<template>
  <div class="billing-address">
    <p>
      Hiermit bestätige ich als Administrator, dass ich den 
      <a href="https://www.bos-id.eu/portal/auftragsverarbeitung" target="_blank" >Auftragsverarbeitungsvertrag (AVV)</a>
      gelesen habe und diesem zustimme.
    </p>
    <div class="input-row" 
        v-if="!(customerConfiguration.dpp_accepted_by_username==null) && !(customerConfiguration.dpp_accepted_at==null)">
      <vs-input class="inputx sm-input mb-2 mr-2" :label-placeholder="$t('signatory')" 
        v-model="customerConfiguration.dpp_accepted_by_username" readonly/>
      <vs-input class="inputx sm-input mb-2 mr-2" :label-placeholder="$t('date')" 
        :value="customerConfiguration.dpp_accepted_at | moment('DD.MM.YYYY HH:mm:ss')" readonly/>
    </div>


    <vs-row class="w-100" 
      v-if="(customerConfiguration.dpp_accepted_by_username==null) && (customerConfiguration.dpp_accepted_at==null) && (this.user.is_customer_admin)">

        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
          <vs-button class="pop-btn organ-btn" color="primary" type="filled" icon="done"
              @click="aggreeDataAgreement()">
              {{$t('Zustimmen')}}</vs-button>
        </vs-col>
    </vs-row>


  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'DataAgreement',

  async mounted() {
    // await this.$store.dispatch('customer/loadCustomer', this.user.customer_id)
    await this.$store.dispatch('customer/loadCustomerConfiguration', this.user.customer_id)
  },

  data(){
    return{
      textarea: '',
      data:''
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    customer() {
      return this.$store.getters['customer/getCustomer']
    },
    customerConfiguration() {
      return this.$store.getters['customer/getCustomerConfiguration']
    },

  },

  methods: {
    async aggreeDataAgreement() {
      await this.$store.dispatch('customer/updateDataAgreement', this.customer)
    }
  }

});
</script>
<style>

</style>